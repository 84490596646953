<template>
  <div class="home">
    <div class="container mt-5">
      
      <h1>Trampoline Model Test Harness</h1>
      <div class="d-flex">
        <div>
          <div class="d-flex">
            <h3>Live Mode</h3>
            <hr/>
          </div>
          <div class="video-container mb-3">
            <div class="canvas-wrapper">
              <canvas id="output"></canvas>
              <video id="video" playsinline style="
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                visibility: hidden;
                width: auto;
                height: auto;
                ">
              </video>
            </div>
            <div id="scatter-gl-container"></div>
          </div>

          <button v-if="!isRecording" class="btn btn-success" @click="start">Start</button>
          <button v-else class="btn btn-danger" @click="stop">Stop</button>
          <button class="mx-2 btn btn-sm btn-warning">Reset</button>

          <div class="mt-2">
            <h3>Results</h3>
            <hr>
            <h5>Is Detected: {{ poses.length > 0 ? 'Yes' : 'No' }}</h5>
            <h5>Is Facing Us: {{ poses.length > 0 && isFacingUs ? 'Yes' : 'No' }}</h5>
            <h5>Rotations: {{ (Math.ceil((rotationCounts / 2))) }}</h5>
            <h5>Athlete Vertical: {{ athleteAngle }}</h5>
          </div>
        </div>
      <div class="ms-2 w-100">
        <h3>Previous Recordings</h3>
        <hr/>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { toRaw } from 'vue'; 

import '@tensorflow/tfjs-backend-webgl';
import * as mpPose from '@mediapipe/pose';

import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);

import * as posedetection from '@tensorflow-models/pose-detection';

import {Camera} from '@/camera';

export default {
  data() {
    return {
      cameraConfig: {
        targetFPS: 60,
        sizeOption: '640 X 480',
      },
      camera: null,
      detector: null,
      renderAnimationId: null,
      isFacingUs: false,
      poses: [],

      isRecording: false,
      mediaRecorder: null,
      recordedChunks: [],

      previousRecordings: [],
      rotationCounts: 0,
      athleteAngle: 0,
    };
  },
  components: {
  },
  mounted() {
    this.setup();
  },
  methods: {
    async setup() {
      if (this.renderAnimationId) {
        window.cancelAnimationFrame(this.renderAnimationId);
      }

      this.camera = await Camera.setupCamera(this.cameraConfig);
      this.detector = await this.createDetector();

      this.renderAnimationId = window.requestAnimationFrame(this.renderPrediction);

      // Setup the recorder
      var theCanvasElementToRecord = document.querySelector('#output');            
      var stream = theCanvasElementToRecord.captureStream(60); 
      var options = { mimeType: 'video/webm;codecs=vp9' };

      this.mediaRecorder = new MediaRecorder(stream, options);   
      this.mediaRecorder.ondataavailable = this.mediaRecorderData
    },
    async renderPrediction() {
      await this.renderResult();
      this.renderAnimationId = window.requestAnimationFrame(this.renderPrediction);
    },
    async renderResult() {
      const camera = this.camera;
      let detector = toRaw(this.detector);

      if (camera.video.readyState < 2) {
        await new Promise((resolve) => {
          camera.video.onloadeddata = () => {
            resolve(video);
          };
        });
      }

      // Detector can be null if initialization failed (for example when loading
      // from a URL that does not exist).
      if (detector != null) {
        // Detectors can throw errors, for example when using custom URLs that
        // contain a model that doesn't provide the expected output.
        try {
          this.poses = await detector.estimatePoses(
              camera.video,
              { maxPoses: 1, flipHorizontal: true});
        } catch (error) {
          detector.dispose();
          this.detector = null;
          console.error(error);
          // alert(error);
        }
      }

      camera.drawCtx();
      // console.log("Drawn")
      camera.drawResults(this.poses);
      this.processPoses();
    },
    processPoses() {
      // Do our analysis
      if (!this.poses || this.poses.length < 0) return;
      if (!this.poses[0] || !this.poses[0].keypoints) return;
      let keypoints = this.poses[0].keypoints;
      const left_hip = keypoints.find(x => x.name == 'left_hip');
      const right_hip = keypoints.find(x => x.name == 'right_hip');
      const left_shoulder = keypoints.find(x => x.name == 'left_shoulder');
      const right_shoulder = keypoints.find(x => x.name == 'right_shoulder');
      const neck_base = { x: (left_shoulder.x + right_shoulder.x) / 2, y: (left_shoulder.y + right_shoulder.y) / 2 }
      const pelvis = { x: (left_hip.x + left_hip.y) / 2, y: (left_hip.x + left_hip.y) / 2 };
      

      let athleteAngle = this.radians_to_degrees(this.find_vertical(neck_base, right_shoulder))
      this.athleteAngle = athleteAngle;
      // If the athlete is horizontal, we need to compare in the Y direction?
      let isNowFacingUs = left_hip.x > right_hip.x;

      if (isNowFacingUs != this.isFacingUs) {
        this.rotationCounts++;
      }
      this.isFacingUs = isNowFacingUs;
      // this.bodyAngle = // Asuming the camera is angled correctly are they flat or vertical
    },
    find_vertical(A, B) {
      let dX = Math.abs(A.x - B.x)
      let dY = Math.abs(A.y - B.y)
      return Math.atan2(dY, dX);
    },
    find_angle(A, B, C) {
      // In radians
      var AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
      var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
      var AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
      return Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
    },
    radians_to_degrees(radians)
    {
      var pi = Math.PI;
      return radians * (180/pi);
    },
    start() {
      if (this.isRecording) return;

      this.mediaRecorder.start();
      this.isRecording = true;
      this.rotationCounts = 0;
    },
    mediaRecorderData(event) {
      console.log("data-available");
      
      // if (event.data.size <= 0) return;

      // this.recordedChunks.push();

      const a = document.createElement('a')
      a.href = URL.createObjectURL(event.data)
      a.download = 'recording.webm'
      a.click()
      this.isRecording = false;
    },
    mediaRecorderStopped() {
    },
    stop() {
      this.mediaRecorder.stop();
      // this.isRecording = false;
      // Save?
      // Dispose the old?

    },
    
    async createDetector() {

      try { 
        // Can we store this locally? Maybe using a custom URL?
        // This has to match params.js until it is refactored out.
        return toRaw(await posedetection.createDetector(posedetection.SupportedModels.MoveNet, { modelType: posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING }));
      } catch (e) {
        console.error("Unable to create pose detector");
        console.error(e);
      }
    }
  }
}
</script>
